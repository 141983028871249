<template>

  <div class="bg-image">
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
      <div class="container">
        <a class="navbar-brand" href="https://cubc.org.hk/" target="_blank">
          <div class="logo-image">
            <img alt="church logo" src="https://cubc.org.hk/wp-content/uploads/2020/05/cropped-UIC-3-32x32.png" class="img-fluid">
          </div>
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <router-link class="nav-link" to="/">賣旗日義工招募</router-link>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link" href="/registerinfo">成為義工</a>
            </li> -->
            <!-- <li class="nav-item">
              <a class="nav-link" href="/flagsSelling">義工專區</a>
            </li> -->

            <li class="nav-item">
              <a class="nav-link" href="https://cubc.org.hk/contact-us/">聯絡我們</a>
            </li>

          </ul>
        </div>
      </div>
    </nav>

    <main>
      <router-view />
    </main>

    <footer class="border-top bg-white mt-3">
        <div class="container">
            <div class="pt-5">
                <div class="row">
                    <div class="col-6 col-md-3 mb-3 text-start">
                        <h5>簡介</h5>
                        <p class="text-muted">實踐基督教訓，關懷社群，<br />服務社區，傳揚基督福音真理</p>
<!--                        <ul class="nav flex-column">-->
<!--                            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Home</a></li>-->
<!--                            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Features</a></li>-->
<!--                            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Pricing</a></li>-->
<!--                            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">FAQs</a></li>-->
<!--                            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">About</a></li>-->
<!--                        </ul>-->
                    </div>

                    <div class="col-6 col-md-3 mb-3 text-start">
                        <h5>網絡資源</h5>
                        <ul class="nav flex-column">
                            <li class="nav-item mb-2"><a href="https://cubc.org.hk/useful-links/" class="nav-link p-0 text-muted" target="_blank">常用連結</a></li>
                            <li class="nav-item mb-2"><a href="https://cubc.org.hk/privacy-statement/" class="nav-link p-0 text-muted" target="_blank">私隱政策聲明</a></li>
                            <li class="nav-item mb-2"><a href="https://cubc.org.hk/copyright/" class="nav-link p-0 text-muted" target="_blank">版權聲明</a></li>
                            <li class="nav-item mb-2"><a href="https://cubc.org.hk/agreement/" class="nav-link p-0 text-muted" target="_blank">免責條款</a></li>
                        </ul>
                    </div>

                    <div class="col-md-5 offset-md-1 mb-3">
                        <a href="/" class="d-flex align-items-center mb-3 link-dark text-decoration-none">
                            <img class="img-fluid" src="https://cubc.org.hk/wp-content/uploads/2016/08/logo_retina_x2.png" alt="Full Logo">
                        </a>
<!--                        <form>-->
<!--                            <h5>Subscribe to our newsletter</h5>-->
<!--                            <p>Monthly digest of what's new and exciting from us.</p>-->
<!--                            <div class="d-flex flex-column flex-sm-row w-100 gap-2">-->
<!--                                <label for="newsletter1" class="visually-hidden">Email address</label>-->
<!--                                <input id="newsletter1" type="text" class="form-control" placeholder="Email address">-->
<!--                                <button class="btn btn-primary" type="button">Subscribe</button>-->
<!--                            </div>-->
<!--                        </form>-->
                    </div>
                </div>

                <div class="d-flex flex-column flex-sm-row justify-content-between pt-4 pb-2 border-top">
                    <p class="text-muted">© 2023 基督教協基會版權所有</p>
                    <ul class="list-unstyled d-flex">
                        <li class="ms-3"><a class="text-muted" href="https://www.facebook.com/cubcservice/" target="_blank"><i class="bi bi-facebook"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
  </div>

</template>

<style>
html, body, .bg-image  { min-height: 100vh }
body {
  --bs-body-bg: rgb(41, 106, 178)
}
.bg-image > main {
    min-height: calc(100vh - 60px - 334px);
}
@keyframes cloud1 {
  from {
    background-position: 100% 60%,
                         30% 35%,
                         20% 25%,
                         60% 15%,
                         50% 70%,
                         40% 85%,
                         70% 99%,
                         95% 30%,
                         10% 50%;
  }
  to {
    background-position: 10% 60%,
                         20% 35%,
                         10% 15%,
                         40% 15%,
                         90% 70%,
                         60% 85%,
                         30% 96%,
                         80% 40%,
                         20% 40%;
  }
}
/* @keyframes bird2 {
  from {
    top:100vw;
  }
  to {
    top:0vw;
  }
} */
/*  */
.bg-image {
  background-image: url('/public/web/BG/BG_雲 雀1.png'), url('/public/web/BG/BG_雲 雀2.png'), url('/public/web/BG/BG_雲 雀3.png'),
  url('/public/web/BG/BG_雲 雀4.png'), url('/public/web/BG/BG_雲 雀5.png'), url('/public/web/BG/BG_雲 雀6.png'),  url('/public/web/BG/BG_雲 雀7.png'),  url('/public/web/BG/BG_雲 雀8.png');
  background-repeat: no-repeat, no-repeat;
  animation-name: cloud1;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  background-size: 15%;
}

@media screen and (max-width: 575px) {
  .bg-image {
    animation-name: none;
    background:#296AB2;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
  background-color: #ffffff!important;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

</style>

<script>
// @ is an alias to /src
import { useRoute } from 'vue-router'
import { onMounted, ref } from 'vue'
export default {
  name: 'SearchView',
  components: {
  },
  setup () {
    const inventory = ref({})
    const route = useRoute()
    const loggedIn = ref(false)
    const isAdmin = ref(false)

    const logout = function () {
      localStorage.removeItem('users')
      localStorage.removeItem('loggedIn')
      localStorage.removeItem('isAdmin')
      location.assign('/')
    }
    onMounted(async () => {
      loggedIn.value = localStorage.getItem('loggedIn')
      isAdmin.value = localStorage.getItem('isAdmin')
    })
    return {
      inventory,
      route,
      loggedIn,
      logout,
      isAdmin
    }
  }
}
</script>
