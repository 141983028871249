<script setup>
</script>

<template>
    <div class="container">

        <div class="card mb-3 rounded-5 overflow-hidden mt-3 bg-white bg-opacity-75">
            <div class="row g-0 bg-white bg-opacity-75">
                <div class="col-md-8 d-block d-sm-none">
                    <img src="../assets/banner.jpg" class="img-fluid" alt="banner">
                </div>
                <div class="col-md-4">
                    <div class="card-body">
                        <h2 class="mt-5">誠邀大家登記成為賣旗義工!</h2>
                        <p class="text-muted lead mt-5 text-start">賣旗活動為弱勢兒童及青少年服務籌款包括:<br>貧困家庭、特殊學習需要、精神健康等</p>
                        <p class="text-muted lead mt-5 text-start">籌款義工設有三種組別，可選擇以個人，親子或機構及學校的名義參與，我們非常鼓勵各個家庭或親朋好友組成隊伍，共同參與扶貧活動。</p>
                    </div>
                </div>
                <div class="col-md-8 d-none d-sm-block">
                    <img src="../assets/banner.jpg" class="img-fluid" alt="banner">
                </div>
            </div>
        </div>

        <div class="card p-3 rounded-5"><h5 class="mb-0">請選擇以下組別登記成為賣旗日義工!</h5></div>

        <div class="row circles mt-3">
            <router-link to="/register/individual" class="col-6 col-md-3">
                <img class="img-fluid" src="../assets/4box/1.png" alt="登記成為個人義工" />
            </router-link>
            <router-link to="/register/parent-with-child" class="col-6 col-md-3">
                <img class="img-fluid" src="../assets/4box/2.png" alt="登記成為親子義工" />
            </router-link>
            <router-link to="/register/group" class="col-6 col-md-3">
                <img class="img-fluid" src="../assets/4box/3.png" alt="機構及學校義工登記" />
            </router-link>
            <a href="/義工招募《回應表》新界區202324.pdf" target="_blank" class="col-6 col-md-3">
                <img class="img-fluid" src="../assets/4box/4.png" alt="下載表格" />
            </a>
        </div>
    </div>
</template>

<style scoped>
.circles > a {
    border-radius: 50% !important;
}
</style>
